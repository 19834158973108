/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/js-cookie@2.2.1/src/js.cookie.min.js
 * - /npm/lodash@4.17.21/lodash.min.js
 * - /npm/moment@2.30.1/moment.min.js
 * - /npm/moment-timezone@0.5.27/builds/moment-timezone-with-data.min.js
 * - /npm/numeral@2.0.6/numeral.min.js
 * - /npm/sugar@2.0.6/dist/sugar.min.js
 * - /npm/js-cookie@2.2.1/src/js.cookie.min.js
 * - /npm/bootstrap@3.4.1/dist/js/bootstrap.min.js
 * - /npm/jasny-bootstrap@3.1.3/dist/js/jasny-bootstrap.min.js
 * - /npm/bootstrap-confirmation2@2.4.4/bootstrap-confirmation.min.js
 * - /npm/select2@4.0.13/dist/js/select2.min.js
 * - /npm/eonasdan-bootstrap-datetimepicker@4.17.47/src/js/bootstrap-datetimepicker.min.js
 * - /npm/bootstrap-daterangepicker@3.1.0/daterangepicker.min.js
 * - /npm/intl-tel-input@17.0.21/build/js/intlTelInput.min.js
 * - /npm/intl-tel-input@16.1.0/build/js/utils.min.js
 * - /npm/lazyload@2.0.0-rc.2/lazyload.min.js
 * - /npm/parsleyjs@2.9.2/dist/parsley.min.js
 * - /npm/mousetrap@1.6.5/mousetrap.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
